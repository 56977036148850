import qs from 'qs';

export type PageParams = {
  fulltext_search_term: string,
  jurisdiction: string,
  citation: string,
  date_from: string,
  date_to: string,
  judge: string,
  counsel: string,
  party: string,
  docket_number: string,
  topic: string,
  title: string,
  page: string,
  order: string,
};

export function getSearchParams(ctx: { params, querystring, pageParams }, next: () => void): void {
  const data = qs.parse(ctx.querystring) as Record<string, string>;
  ctx.pageParams = {
    fulltext_search_term: data['fulltext_search_term'],
    jurisdiction: data['jurisdiction'],
    database: data['database'],
    issuer: data['issuer'],
    citation: data['citation'],
    date_from: data['date_from'],
    date_to: data['date_to'],
    judge: data['judge'],
    counsel: data['counsel'],
    party: data['party'],
    topic: data['topic'],
    docket_number: data['docket_number'],
    title: data['title'],
    page: data['page'],
    order: data['order'],
  };

  next();
}
