<script lang="ts">
  import Logo from 'images/logo.svg';

  export let small = false;
</script>

<style lang="postcss">
  a {
    @apply block mx-4 text-right;

    width: 186px;
    vertical-align: middle;
    transition: all ease-in-out 0.2s;
  }

  a.small {
    width: 120px;
    transition: all ease-in-out 0.2s;
  }

  img {
    display: block;
    transition: all ease-in-out 0.2s;
  }

  img.small {
    transition: all ease-in-out 0.2s;
  }
</style>

<a class:small href="/">
  <img alt="compass.law search" class:small src={Logo} title="compass.law search" />
</a>
