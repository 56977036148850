<script lang="ts">
  import { search, SearchRequest, SearchResult } from 'api';
  import BaseLayout from 'components/elements/BaseLayout.svelte';
  import SearchFiltersParams from 'components/searchform/SearchFiltersParams.svelte';
  import AdBar from 'components/searchresults/AdBar.svelte';
  import Counter from 'components/searchresults/Counter.svelte';
  import Pager from 'components/searchresults/Pager.svelte';
  import ResultsList from 'components/searchresults/ResultsList.svelte';
  import SortControl from 'components/searchresults/SortControl.svelte';
  import Navbar from 'components/sidebar/Navbar.svelte';
  import pagejs from 'page';
  import { onMount } from 'svelte';
  import { DialogContent, DialogOverlay } from 'svelte-accessible-dialog';
  import { MetaTags } from 'svelte-meta-tags';
  import { basePageMeta, baseRobots } from 'utils/page';
  import { PageParams } from 'utils/params';
  import { SearchHash, toQueryString } from 'utils/querystring';

  // pageParams are the query string parameters passed by the router.
  export let pageParams: PageParams;
  export let ready: boolean;

  //
  let filterParams: Record<string, string> = {};

  // Search
  let searchReq: SearchRequest;
  let searchResult: SearchResult;
  let page = 1;
  let perPage = 15;
  let order = '';
  let limitReached = false;
  let error = null;

  // State
  let searching: boolean;
  let sidebarOpen = false;

  // Update the filterParams when page params change:
  $: {
    filterParams = {
      ...pageParams,
    };
  }

  onMount(() => {
    // If there is no page parameter, go back to the home page
    if (!filterParams.page) {
      pagejs.redirect('/');
    }

    doSearch();
  });

  // Apply the search filter changes to the query string.
  async function searchFilterChanged({ detail }: { detail: SearchHash }) {
    // Always set to the first page when the filter is changed.
    page = 1;

    // Build query string
    let qstr = toQueryString(detail, page);

    // Apply page history
    await pagejs(`/search?${qstr}`);

    // Run search
    await doSearch();
  }

  function updateSearchRequest() {
    searchReq = <SearchRequest>{
      fulltext_search_term: filterParams['fulltext_search_term'],
      jurisdiction: filterParams['jurisdiction'],
      database: filterParams['database'],
      issuer: filterParams['issuer'],
      citation: filterParams['citation'],
      date_from: filterParams['date_from'],
      date_to: filterParams['date_to'],
      judge: filterParams['judge'],
      counsel: filterParams['counsel'],
      party: filterParams['party'],
      topic: filterParams['topic'],
      docket_number: filterParams['docket_number'],
      title: filterParams['title'],
      per_page: perPage,
      page: Number(pageParams.page),
      order: pageParams.order,
    };

    page = Number(pageParams.page);
  }

  async function changePage() {
    const hash: SearchHash = {
      fullTextSearchTerm: searchReq.fulltext_search_term,
      dateFrom: searchReq.date_from,
      dateTo: searchReq.date_to,
      citation: searchReq.citation,
      docketNumber: searchReq.docket_number,
      jurisdiction: searchReq.jurisdiction,
      database: searchReq.database,
      issuer: searchReq.issuer,
      judge: searchReq.judge,
      counsel: searchReq.counsel,
      party: searchReq.party,
      topic: searchReq.topic,
      title: searchReq.title,
      order: order,
    };

    let qstr = toQueryString(hash, page);
    await pagejs(`/search?${qstr}`);
  }

  async function doSearch() {
    sidebarOpen = false;

    await updateSearchRequest();
    console.log('search request: ', searchReq);

    searching = true;
    searchResult = null;

    try {
      searchResult = await search(searchReq);
      console.log('search result: ', searchResult);

      limitReached = searchResult.limit_reached;
    } catch (err) {
      console.error('search request error:', err);
      error = err;
    } finally {
      searching = false;
    }

    window.scrollTo(0, 0);
  }

  async function nextPage() {
    page = page + 1;
    await changePage();
    await doSearch();
  }

  async function previousPage() {
    page = page - 1;
    if (page == 0) {
      page = 1;
    }
    await changePage();
    await doSearch();
  }

  async function changeOrder(e) {
    order = e.detail;
    await changePage();
    await doSearch();
  }

  // Internal change handler for the filter controls
  function filterChange(e) {
    const data: Record<string, string> = e.detail;

    filterParams = {
      fulltext_search_term: data['fulltext_search_term'],
      jurisdiction: data['jurisdiction'],
      issuer: data['issuer'],
      date_from: data['date_from'],
      date_to: data['date_to'],
      citation: data['citation'],
      docket_number: data['docket_number'],
      database: data['database'],
      judge: data['judge'],
      party: data['party'],
      topic: data['topic'],
      counsel: data['counsel'],
      title: data['title'],
    };
  }
</script>

<style lang="postcss">
  :global([data-svelte-dialog-content].content) {
    @apply w-screen sm:w-[90vw] mx-auto mt-[96px] max-w-[580px] z-40;
  }

  :global([data-svelte-dialog-overlay].overlay) {
    @apply w-screen h-screen;

    background: rgba(0, 0, 0, 0.66);
  }
</style>

<MetaTags
  additionalMetaTags={[
    ...basePageMeta
  ]}
  description="compass.law search engine"
  robotsProps={{
    ...baseRobots,
    noarchive: true,
    nosnippet: true,
    maxImagePreview: 'none',
    maxVideoPreview: -1,
    maxSnippet: -1,
    noimageindex: true,
  }}
  title="compass.law search"
/>

<BaseLayout>
  <!-- Nav bar -->
  <Navbar bind:sidebar={sidebarOpen} />

  <!-- Sidebar with filters -->
  <DialogOverlay class="overlay" isOpen={sidebarOpen} onDismiss={() => { sidebarOpen = false; }}>
    <DialogContent class="content">
      <SearchFiltersParams bind:filterParams={filterParams}
                           disabled={searching}
                           hideAdvancedToggle={true}
                           on:change={filterChange}
                           on:submitSearch={searchFilterChanged}
                           {ready}
                           showAdvanced={true}
                           wideMode={false} />

    </DialogContent>
  </DialogOverlay>

  <div class="w-full flex flex-row bg-white">
    <!-- Search filters -->
    <div class="w-1/4 min-w-[325px] hidden lg:block">
      <SearchFiltersParams bind:filterParams={filterParams}
                           disabled={searching}
                           hideAdvancedToggle={true}
                           on:change={filterChange}
                           on:submitSearch={searchFilterChanged}
                           {ready}
                           showAdvanced={true}
                           wideMode={false}
      />
    </div>

    <!-- Error -->
    {#if error}
      <div class="w-full p-4">
        <div class="bg-white text-red-700 border border-red-700 w-full p-4">
          <div class="text-lg mb-1 font-bold">Search request error</div>
          <div>{error.message || error.data}</div>
        </div>
      </div>
    {/if}

    {#if searching}
      <div class="w-full p-6">
        <div class="text-xl text-center">
          Loading...
        </div>
      </div>
    {/if}

    <!-- Search results and ad bar -->
    {#if searchResult}
      {#if searchResult.hits > 0}
        <div class="w-full border-l border-dotted border-gray-400">
          <!-- Results header -->
          <div class="w-auto flex flex-row border-b border-dotted border-gray-200">
            <!-- Results counter -->
            <div class="w-1/2 text-sm uppercase p-4">
              <Counter currentPage={page} perPage={perPage} count={searchResult.items.length}
                       total={searchResult.hits} limit={searchResult.limit} />
            </div>

            <!-- Sort control -->
            <div class="w-1/2 p-4 text-right">
              <SortControl value={pageParams['order']} on:change={changeOrder} />
            </div>
          </div>

          <!-- Results list -->
          <div class="w-full">
            <div class="p-4">
              <ResultsList items={searchResult.items} />
            </div>
          </div>

          <!-- Pager -->
          <Pager
            currentPage={page}
            itemsPerPage={perPage}
            total={searchResult.hits}
            limitReached={limitReached}
            on:nextPage={nextPage}
            on:previousPage={previousPage}
          />
        </div>

        <!-- Ad bar -->
        <div class="w-auto p-4 hidden lg:block">
          <AdBar />
        </div>
      {:else}
        <div class="w-full p-10">
          <div class="text-gray-400 text-2xl text-center">
            No documents found.
          </div>
        </div>
      {/if}
    {/if}
  </div>
</BaseLayout>

<!-- Run search when going back/forward on the browser -->
<svelte:window on:popstate={() => { doSearch(); }} />
