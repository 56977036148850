<script lang="ts">
  import pagejs from 'page';
  import { createEventDispatcher } from 'svelte';
  import { SearchResultItem } from 'api/search';
  import { selectCitation } from 'utils/citations';
  import { formatDate } from 'utils/date';

  // Properties
  export let item: SearchResultItem;

  let mainTitle: string;
  $: {
    if (item && item.titles) {
      mainTitle = item.titles[0].title;
    } else {
      mainTitle = '[No title]';
    }
  }

  let mainCitation: string;
  $: {
    if (item && item.citations) {
      mainCitation = selectCitation(item.citations);
    } else {
      mainCitation = '';
    }
  }

  // Others
  const dispatch = createEventDispatcher();

  // Dispatches an 'onLinkClick' event with the decision ID and URL.
  function onLinkClick(): void {
    dispatch('onLinkClick', {
      decisionID: item.decisionID,
    });

    pagejs(`/decisions/${item.decisionID}`);
  }
</script>

<style lang="postcss">
  .decision-link {
    @apply underline text-blue-600 text-lg;
  }

  .item-container {
    @apply bg-white p-2;
  }

  .header {
    @apply flex flex-col sm:flex-row;
  }

  .title-container {
    @apply flex-auto;
  }
</style>

<div class="item-container">
  <div class="header">
    <div class="title-container">
      <a class="decision-link" href="/decisions/{item.decisionID}" on:click|preventDefault={onLinkClick}>
        {mainTitle}
      </a>
      <span class="text-xs text-gray-400 select-all hidden">{item.decisionID}</span>
    </div>
    <div class="text-sm md:text-right">{formatDate(item.date)}</div>
  </div>

  <div class="flex flex-row space-x-4">
    <div>{mainCitation} &middot; {item.issuer}</div>
  </div>
</div>
