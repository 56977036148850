<script lang="ts">
  import BaseLayout from 'components/elements/BaseLayout.svelte';
  import Navbar from 'components/sidebar/Navbar.svelte';
  import { appState } from 'stores';
  import { buildString, versionString } from '../console';

  export let ready: boolean;

  let version = appState.version;
</script>

<style lang="postcss">
  .info {
    @apply hidden;
    @apply p-6 text-center font-mono;
  }

  .info.ready {
    @apply block;
  }
</style>

<BaseLayout>
  <Navbar noSidebar />
  <div class="info" class:ready>
    <div>
      API version: {$version}
    </div>

    <div>
      Client version: {versionString}
    </div>

    <div>
      Client build: {buildString}
    </div>
  </div>
</BaseLayout>
