<script lang="ts">
  import Base from './Base.svelte';
</script>

<Base tooltipID="fulltext">
  <div class="mb-3">
    <div class="text-lg font-bold">Full-text search</div>
    <p>Returns documents based on a simple query text. All terms must appear in the document.</p>
  </div>

  <div class="">
    <div class="text-lg font-bold">Examples</div>
    <div class="examples">
      <div class="item">
        <div class="title">Presence of multiple terms</div>
        <div class="field">
          copyright music
        </div>
        <div>
          Returns documents containing both the terms "copyright" and "music".
        </div>
      </div>

      <div class="item">
        <div class="title">Exact phrase</div>
        <div class="field">
          "freedom of expression"
        </div>
        <div>
          Returns documents containing the exact phrase "freedom of expression".
        </div>
      </div>

      <div class="item">
        <div class="title">Negative term</div>
        <div class="field">
          license -vehicle
        </div>
        <div>
          Returns documents containing the term "license" but does not contain "vehicle".
        </div>
      </div>

      <div class="item">
        <div class="title">Combination</div>
        <div class="field">
          "citizenship and immigration" "united states" -fraud
        </div>
        <div>
          Returns documents containing &quot;citizenship and immigration&quot; and &quot;united states&quot;
          but does not contain &quot;fraud&quot;
        </div>
      </div>
    </div>
  </div>
</Base>
