<script lang="ts">
  import { setBaseURL } from 'api';
  import { appState, loadApp } from 'stores';
  import { onMount } from 'svelte';
  import { get } from 'svelte/store';
  import { printAPIInfo, printBanner } from './console';
  import Router from './Router.svelte';

  export let apiBase: string;

  setBaseURL(apiBase);

  onMount(async () => {
    printBanner();
    await loadApp();
    printAPIInfo(apiBase, get(appState.version));
  });
</script>

<Router />
