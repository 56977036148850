<script lang="ts">
  import Base from './Base.svelte';
</script>

<Base tooltipID="citation">
  <div class="text-lg font-bold">
    Citation filter
  </div>
  <div>
    Include only documents that contains the text in the citation.
  </div>

  <div class="examples">
    <div class="item">
      <div class="title">Partial terms</div>
      <div class="field">
        2018 ABQB
      </div>
      <div>
        Include only documents with citation that contains "2018" and "ABQB".
      </div>
    </div>

    <div class="item">
      <div class="title">Exact phrase</div>
      <div class="field">
        2018 ABQB 636
      </div>
      <div>
        Includes only documents with citation that contains "2018 ABQB 636".
      </div>
    </div>
  </div>
</Base>
