declare const __VERSION__: string;
declare const __BUILD_DATE__: string;

const fontSize = 10;

const banner =
  `    __________  __  _______  ___   __________  
    / ____/ __ \\/  |/  / __ \\/   | / ___/ ___/  
   / /   / / / / /|_/ / /_/ / /| | \\__ \\\\__ \\   
  / /___/ /_/ / /  / / ____/ ___ |___/ /__/ /   
  \\____/\\____/_/  /_/_/   /_/  |_/____/____/    
   
   Search version: ${__VERSION__}                           
   Search build: ${__BUILD_DATE__}`;

export function printBanner(): void {
  console.log(`%c ${banner}`, `color: #FF8E21; font-size: ${fontSize}px; font-weight: bold;`);
}

export function printAPIInfo(apiBase: string, version: string): void {
  const apiServerBanner =
    `API server: ${apiBase}
   API server version: ${version}`;
  console.log(`%c   ${apiServerBanner}`, `color: #00bbff; font-size: ${fontSize}px; font-weight: bold;`);
}

export const versionString = `${__VERSION__}`;
export const buildString = `${__BUILD_DATE__}`;
