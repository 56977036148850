import Icon from 'images/icon1x.png';

export const basePageMeta = [
  { charset: 'utf-8' },
  {
    name: 'viewport',
    content: 'width=device-width,initial-scale=1,maximum-scale=1'
  },
  {
    name: 'theme-color',
    content: '#000'
  },
];

export const basePageHeadLinks = [
  {
    rel: 'icon',
    type: 'image/png',
    href: Icon,
  }
];

export const baseOpenGraph = {
  site_name: 'compass.law search',
  additionalLinkTags: [
    {
      rel: 'apple-touch-icon',
      href: Icon,
      sizes: '301x301',
    }
  ],
  images: [
    {
      url: Icon,
      alt: 'Compass Law Search',
      height: 301,
      width: 301,
    },
  ]
};

export const baseRobots = {
  notranslate: true,
};
