<script lang="ts">
  import FieldContainer from './FieldContainer.svelte';

  export let value = '';
  export let label = '';
  export let showLabel = true;

  // States
  export let disabled = false;
</script>

<style lang="postcss">
  .date-field {
    @apply border-2 border-black border-solid p-2 w-full bg-white;

    border-width: 1px;
    border-color: #cdcdcd;
    -webkit-appearance: none;
    -moz-appearance: none;
    min-height: 42px;
  }
</style>

<FieldContainer label={label} showLabel={showLabel}>
  <input {disabled} type="hidden" />
  <input bind:value={value}
         class="date-field"
         {disabled}
         tabindex="0"
         type="date" />
</FieldContainer>
