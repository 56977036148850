<script lang="ts">
  import Base from './Base.svelte';
</script>

<Base tooltipID="title">
  <div class="text-lg font-bold">
    Title filter
  </div>
  <div>
    Include only documents that contains the text in the title.
  </div>

  <div class="examples">
    <div class="item">
      <div class="title">Multiple terms</div>
      <div class="field">
        human rights tribunal
      </div>
      <div>
        Include only documents with a title that contain all the words "human", "rights", and "tribunal"
        (in any order).
      </div>
    </div>

    <div class="item">
      <div class="title">Exact phrase</div>
      <div class="field">
        "bank of canada"
      </div>
      <div>
        Includes only documents with a title that contains the phrase "bank of canada".
      </div>
    </div>

    <div class="item">
      <div class="title">Negative term</div>
      <div class="field">
        "bank of canada" -national
      </div>
      <div>
        Includes only documents with a title that contains the phrase "bank of canada" but not "national".
      </div>
    </div>
  </div>
</Base>
