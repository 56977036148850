<script lang="ts">
  import FlatLayout from './FlatLayout.svelte';
  import PageFooter from './PageFooter.svelte';
  import Tailwind from './Tailwind.svelte';

  let flatLayout = false;
</script>

<Tailwind />

{#if flatLayout}
  <FlatLayout>
    <slot></slot>
  </FlatLayout>
{:else}
  <!-- Page up to browser edge -->
  <div class="md:p-6">

    <!-- This is the sizer that maximizes the contents -->
    <div class="mx-auto lg:max-w-screen-2xl max-w-full">

      <!-- Container for the header and contents -->
      <div class="bg-white rounded-t-none md:rounded-2xl">

        <!-- Container for the contents -->
        <div class="flex flex-col rounded-none md:rounded-b-2xl shadow-md mb-2">

          <!-- This just creates the rounded corners at the bottom -->
          <div class="w-full pb-4">

            <!-- Contents go here -->
            <slot>
            </slot>
          </div>
        </div>
      </div>

      <!-- Footer elements -->
      <PageFooter />
    </div>
  </div>
{/if}
