import tippy from 'tippy.js';
import { settings } from './settings';

export function showMe(node: HTMLScriptElement, opts: { template: string }): { destroy: () => void } | null {
  // Return null if template is not set.
  if (!opts.template) {
    return null;
  }

  // Return null if template not found in the DOM tree.
  const tpl = document.getElementById(opts.template);
  if (!tpl) {
    return null;
  }

  let showTooltips = true;
  let instance: { hide: () => void } | null = null;
  const settingsUnsub = settings.subscribe((value) => {
    showTooltips = value.show_tooltips;
    if (instance) {
      instance.hide();
    }
  });

  const t = tippy(node, {
    content: tpl,
    delay: 250,
    allowHTML: true,
    placement: 'bottom-start',
    animation: 'perspective',
    interactive: true,
    interactiveDebounce: 125,
    trigger: 'focusin',
    onShow(inst) {
      instance = inst;
      if (!showTooltips) {
        return false;
      }
    },
  });

  return {
    destroy() {
      settingsUnsub();
      t.destroy();
    }
  }
}
