<script lang="ts">
  import axios from 'axios';
  import { onMount } from 'svelte';

  export let docURL: string;
  let loading = false;
  let iframe = null;
  let error = null
  let loaded = false;
  let framedoc = null;

  const htc = axios.create({
    withCredentials: true,
  });

  onMount(() => {
    framedoc = iframe.contentDocument;
    // This initial blank string is important in Firefox.
    setContent('');
    loadDocument();
  });

  async function loadDocument() {
    error = null;
    loading = true;

    try {
      const res = await htc.get(docURL);
      setContent(res.data);
      loaded = true;
    } catch (err) {
      error = err;
      console.error('error: ', err);
    } finally {
      loading = false;
    }
  }

  function setContent(content: string) {
    if (framedoc === null) {
      console.log('framedoc is null');
      return;
    }

    try {
      framedoc.open();
      framedoc.write(content);
      framedoc.close();
    } catch (error) {
      console.error(error);
    }
  }
</script>

<style lang="postcss">
  .iframe {
    width: 100%;
    height: 100vh;
    margin-top: 20px;
  }
</style>

<div class="">
  {#if loading}
    <div class="text-center text-2xl p-6 text-gray-300">
      Loading
    </div>
  {:else}
    {#if error}
      <div class="text-center p-6">
        <div class="text-red-800 text-sm uppercase">
          The document failed to load.
        </div>
      </div>
    {/if}
  {/if}

  <iframe bind:this={iframe} class="iframe" class:hidden={!!error} title="">
  </iframe>
</div>
