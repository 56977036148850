import { api, okRequest } from './api';

export type Issuer = {
  id: string
  name: string
};

export async function getIssuers(): Promise<Issuer[]> {
  return okRequest(api.get('/issuers'));
}
