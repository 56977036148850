<script lang="ts">
  import { DecisionInfo } from 'api';
  import { formatDate } from 'utils/date';
  import MetadataList from './MetadataList.svelte';
  import Topics from './Topics.svelte';

  export let decision: DecisionInfo;
</script>

<style lang="postcss">
  aside {
    @apply block lg:border-r p-4 bg-gray-100 rounded-lg;
  }

  .meta-unit {
    @apply py-2;
  }

  .meta-unit .label {
    @apply text-sm font-semibold uppercase text-gray-900 mb-1;
  }

  .meta-unit .value {
    @apply text-sm select-all;
  }
</style>

<aside>
  <div class="">
    <div class="text-base uppercase text-gray-700 font-semibold">Decision Metadata</div>

    <div class="meta-unit">
      <div class="label">Compass Decision ID:</div>
      <div class="value"><span class="text-xs">{decision.id}</span></div>
    </div>

    <div class="meta-unit">
      <div class="label">Docket number:</div>
      <div class="value">{decision.docket_number || '-'}</div>
    </div>

    <div class="meta-unit">
      <div class="label">Date:</div>
      <div class="value">{formatDate(decision.date)}</div>
    </div>

    <div class="meta-unit">
      <div class="label">Country:</div>
      <div class="value">{decision.country}</div>
    </div>

    <div class="meta-unit">
      <div class="label">Issuer:</div>
      <div class="value">{decision.issuer ? decision.issuer.name : '-'}</div>
    </div>

    <div class="meta-unit">
      <div class="label">Database:</div>
      <div class="value">{decision.database}</div>
    </div>

    <div class="meta-unit">
      <div class="label">Jurisdiction</div>
      <div class="value">{decision.jurisdiction}</div>
    </div>
    <hr class="mt-4" />
  </div>

  <div class="">
    <div class="meta-unit">
      <div class="label">Cited as:</div>
      <MetadataList collection={decision.citations} property="name" />
    </div>

    <div class="meta-unit">
      <div class="label">Titles:</div>
      <MetadataList collection={decision.titles} property="title" />
    </div>

    <div class="meta-unit">
      <div class="label">Judges:</div>
      <MetadataList collection={decision.judges} property="name" />
    </div>

    <div class="meta-unit">
      <div class="label">Counsels:</div>
      <MetadataList collection={decision.counsels} property="name" />
    </div>

    <div class="meta-unit">
      <div class="label">Parties:</div>
      <MetadataList collection={decision.parties} property="name" />
    </div>

    <div class="meta-unit">
      <div class="label">Topics</div>
      <Topics topics={decision.topics} />
    </div>
  </div>
</aside>
