import qs from 'qs';

export type SearchHash = {
  fullTextSearchTerm: string,
  jurisdiction: string[],
  issuer: string[],
  database: string[],
  citation: string,
  dateFrom: string,
  dateTo: string,
  judge: string,
  party: string,
  topic: string,
  counsel: string,
  title: string,
  docketNumber: string,
  order: string,
};

export function toQueryString(hash: SearchHash, page: number): string {
  const data: Record<string, unknown> = {};

  if (hash.fullTextSearchTerm) {
    data['fulltext_search_term'] = hash.fullTextSearchTerm;
  }

  if (hash.jurisdiction) {
    data['jurisdiction'] = hash.jurisdiction;
  }

  if (hash.dateFrom) {
    data['date_from'] = hash.dateFrom;
  }

  if (hash.dateTo) {
    data['date_to'] = hash.dateTo;
  }

  if (hash.citation) {
    data['citation'] = hash.citation;
  }

  if (hash.database) {
    data['database'] = hash.database;
  }

  if (hash.docketNumber) {
    data['docket_number'] = hash.docketNumber;
  }

  if (hash.judge) {
    data['judge'] = hash.judge;
  }

  if (hash.counsel) {
    data['counsel'] = hash.counsel;
  }

  if (hash.party) {
    data['party'] = hash.party;
  }

  if (hash.issuer) {
    data['issuer'] = hash.issuer;
  }

  if (hash.topic) {
    data['topic'] = hash.topic;
  }

  if (hash.title) {
    data['title'] = hash.title;
  }

  if (hash.order) {
    data['order'] = hash.order;
  } else {
    data['order'] = '';
  }

  data['page'] = page;

  return encodeURIComponent(qs.stringify(data, {}));
}
