<script lang="ts">
  import Tailwind from 'components/elements/Tailwind.svelte';

  export let ready: boolean;
</script>

<Tailwind />

<div class="sm:p-6 p-0">
  <div class="hidden">{ready}</div>
  <div class="mx-auto lg:max-w-screen-2xl max-w-full">
    <div class="flex flex-col h-screen justify-between">
      <div class="mb-auto">
        <div class="text-center mt-[100px]">
          <h1 class="text-8xl font-bold transform-gpu origin-center -rotate-3">404</h1>
          <h1 class="text-4xl font-bold transform-gpu origin-center -rotate-2">not found</h1>
          <div class="mt-[50px]">
            <a class="underline text-lg text-gray-700" href="/">Home</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
