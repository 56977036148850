<script lang="ts">
  import { DocumentPreview } from 'api';
  import HTMLFrame from './HTMLFrame.svelte';

  // decisionID references the decision ID these documents belong to
  export let decisionID: string;

  // fileID is the selected document file to be focused on
  export let fileID: string;

  // documents is the list of documents
  export let documents: Array<DocumentPreview> = [];

  let doclist: Array<DocumentPreview> = [];

  // This sorts the list of documents into doclist, which is the same list
  // but sorted so the documents appear in this label order:
  const labels = ['headnotes', 'content', 'other'];
  $: {
    let labelgroups: Record<string, Array<DocumentPreview>> = {};
    doclist = [];

    documents.forEach(d => {
      labelgroups[d.label] ??= [];
      labelgroups[d.label].unshift(d);
    });

    labels.forEach(label => {
      if (!labelgroups[label]) {
        return;
      }

      labelgroups[label].forEach(d => {
        doclist.push(d);
      });
    });

    if (doclist && doclist[0] && !fileID) {
      fileID = doclist[0].id;
    }
  }
</script>

<style lang="postcss">
  .doc {
    width: 100%;
    height: 100vh;
  }

  .tabs {
    @apply w-full flex flex-row space-x-2 border-b border-gray-400 border-dotted;
  }

  .tab-button {
    @apply border-t border-l border-r border-gray-400 px-2 py-1 text-xs uppercase text-gray-700;
    @apply hover:text-white hover:bg-gray-500 active:text-white active:bg-gray-400;
    @apply cursor-pointer select-none;
  }

  .tab-button.selected {
    @apply bg-black text-white border-none;
  }
</style>

{#if doclist}
  <div class="tabs">
    {#each doclist as document, n}
      <a href="/decisions/{decisionID}/{document.id}" class="tab-button"
         class:selected={fileID ? (document.id === fileID) : false}
         role="button" tabindex="0">
        {n + 1} &middot; {document.label}
      </a>
    {/each}
  </div>

  {#each doclist as document}
    <div class:hidden={fileID ? fileID !== document.id : true}>
      {#if document.filetype === 'text/html'}
        <HTMLFrame docURL={document.storage_url} />
      {:else}
        <object type={document.filetype} data={document.storage_url} class="doc" title="">
        </object>
      {/if}
    </div>
  {/each}
{/if}
