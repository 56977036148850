<script lang="ts">
  import { DecisionInfo } from 'api';
  import { onMount } from 'svelte';
  import { selectCitation } from 'utils/citations';
  import { formatDate } from 'utils/date';
  import DecisionFiles from './DecisionFiles.svelte';

  // decision contains the decision info
  export let decision: DecisionInfo;

  // file_id is the selected document file ID
  export let file_id: string;

  // title is always fetched from the decision's primary title
  let title: string;
  $: {
    if (decision && decision.titles && decision.titles[0]) {
      title = decision.titles[0].title;
    } else {
      title = 'No title';
    }
  }

  // Select main citation
  let citation: string;
  $: {
    if (decision && decision.citations) {
      citation = selectCitation(decision.citations);
    } else {
      citation = '';
    }
  }

  onMount(() => {
    console.log('decision: ', decision);
  });
</script>

{#if decision}
  <div class="w-full">
    <!-- Title -->
    <div class="text-center font-semibold text-2xl">{title}</div>
    <div class="text-center text-lg">
      {formatDate(decision.date)}
      &middot;
      {decision.issuer ? decision.issuer.name : ''}
    </div>

    <!-- Docket number -->
    <div class="text-center">{decision.docket_number}</div>

    <!-- Citation -->
    <div class="text-center">{citation}</div>

    <!-- Content (files) -->
    <div class="w-full mt-4">
      <DecisionFiles documents={decision.documents} decisionID={decision.id} fileID={file_id} />
    </div>
  </div>
{/if}
