import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import { Readable, readable, Writable, writable } from 'svelte/store';

const currentCalls: Writable<number> = writable(0);

export const inflights: Readable<number> = readable(0, function start(set) {
  const unsub = currentCalls.subscribe((n) => set(n));

  return function stop() {
    unsub();
  }
});

const errorSubscribers: Record<string, unknown> = {};

export function addErrorSubscriber(s: (any) => void): () => void {
  const subscriberID = random();
  errorSubscribers[subscriberID] = s;

  return () => {
    delete errorSubscribers[subscriberID];
  };
}

let apiURL = '';

export function setBaseURL(baseURL: string): void {
  apiURL = baseURL;
}

export function getBaseURL(): string {
  return apiURL;
}

export const api = axios.create({
  baseURL: apiURL,
  withCredentials: true,
});

// This interceptor sets the axios baseURL from our apiURL.
api.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    config.baseURL = apiURL;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//
api.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    currentCalls.update((n) => n + 1);
    return config;
  },
  (error) => {
    currentCalls.update((n) => n - 1);
    return Promise.reject(error);
  }
);

//
api.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    currentCalls.update((n) => n - 1);
    return response;
  },
  (error) => {
    currentCalls.update((n) => n - 1);
    return Promise.reject(error);
  }
);

export async function okRequest(actionPromise: Promise<any>): Promise<any> {
  return new Promise((resolve, reject) => {
    actionPromise
      .then((res) => {
        if (res.status !== 200) {
          reject(new Error('response not ok'));
          return;
        }

        resolve(res.data);
      })
      .catch((err) => {
        if (err.response) {
          const data = err.response.data;
          reject({
            status: err.response.status,
            statusText: err.response.statusText,
            data,
          });
        } else {
          reject(err);
        }

        if (_.size(errorSubscribers) > 0) {
          _.forEach(errorSubscribers, (s) => {
            try {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              s(err);
            } catch (sErr) {
              console.error('error subscriber error', sErr);
            }
          });
        }
      });
  });
}


function random(): string {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}
