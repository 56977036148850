<script lang="ts">

</script>

<style lang="postcss">
  .main {
    @apply bg-gray-200 border border-dotted border-gray-400 p-4;

    min-height: 1000px;
    min-width: 220px;
  }
</style>

<div class="main">
  <div class="text-center uppercase text-gray-400 text-sm">
    Ad area
  </div>
</div>
