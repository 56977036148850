import { writable } from 'svelte/store';

export const localSettings = (key: string, initial: unknown): { subscribe, set, update } => {
  const toString = (value) => JSON.stringify(value, null, 2);

  const toObj = JSON.parse;

  if (localStorage.getItem(key) === null) {
    localStorage.setItem(key, toString(initial));
  }

  const saved = toObj(localStorage.getItem(key) || '');

  const { subscribe, set, update } = writable(saved);

  return {
    subscribe,
    set: (value) => {
      localStorage.setItem(key, toString(value));
      return set(value);
    },
    update,
  };
}

export const settings = localSettings('compass.settings', {
  show_tooltips: true,
  show_advanced_filters: false,
});
