<script lang="ts">
  import { SearchResultItem } from 'api/search';
  import ResultItem from './ResultItem.svelte';

  export let items: [SearchResultItem] = [];
</script>

<style lang="postcss">
  .results-list {
    @apply w-full bg-white;
  }

  .item-container {
    @apply block;
  }
</style>

<div class="results-list">
  {#each items as item}
    <div class="item-container">
      <ResultItem item={item} />
    </div>
  {/each}
</div>
