<script lang="ts">
  import BaseLayout from 'components/elements/BaseLayout.svelte';
  import SearchFiltersParams from 'components/searchform/SearchFiltersParams.svelte';
  import Navbar from 'components/sidebar/Navbar.svelte';
  import pagejs from 'page';
  import { MetaTags } from 'svelte-meta-tags';
  import { baseOpenGraph, basePageHeadLinks, basePageMeta, baseRobots } from 'utils/page';
  import { PageParams } from 'utils/params';
  import { SearchHash, toQueryString } from 'utils/querystring';
  import { settings } from 'utils/settings';

  export let pageParams: PageParams = null;
  let filterParams: Record<string, string> = {};
  $: {
    filterParams = {
      ...pageParams,
    };
  }

  // State
  export let ready: boolean;

  async function submitSearch({ detail }: { detail: SearchHash }) {
    const page = 1;
    const qstr = toQueryString(detail, page);
    pagejs(`/search?${qstr}`);
  }
</script>

<MetaTags
  additionalLinkTags={[...basePageHeadLinks]}
  additionalMetaTags={[...basePageMeta]}
  canonical="https://search.compass.law"
  description="Canadian case law research site with detailed topic search covering 180,000 cases including nearly all appeal cases between 1990 and 2016."
  openGraph={{
      ...baseOpenGraph,
      url: 'https://search.compass.law',
      title: `compass.law search`,
      description: 'Canadian case law research site with detailed topic search covering 180,000 cases including nearly all appeal cases between 1990 and 2016.',
    }}
  robotsProps={{...baseRobots}}
  title="compass.law search"
/>

<BaseLayout>
  <Navbar noSidebar={true} />
  <SearchFiltersParams bind:showAdvanced={$settings['show_advanced_filters']}
                       filterParams={filterParams}
                       on:submitSearch={submitSearch}
                       ready={ready}
                       showPlaceholders={false}
                       wideMode={true}
  />
</BaseLayout>
