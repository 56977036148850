<script>
  import Hamburger from './Hamburger.svelte';
  import Title from '../elements/Title.svelte';
  import { onDestroy, onMount } from 'svelte';

  export let sidebar = false;
  export let noSidebar = false;

  let small = false;

  let ticking = false;
  let lastScrollPos = 0;

  const scrollShrinkPoint = 25;

  function scrollHandler() {
    lastScrollPos = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        small = lastScrollPos > scrollShrinkPoint;
        ticking = false;
      });

      ticking = true;
    }
  }

  onMount(() => {
    document.addEventListener('scroll', scrollHandler);
  });

  onDestroy(() => {
    document.removeEventListener('scroll', scrollHandler);
  });

</script>

<style lang="postcss">
  header {
    @apply pl-2 flex justify-between bg-black items-center text-white md:rounded-t-2xl sticky top-0;

    height: 96px;
    transition: all ease-in-out 0.2s;
  }

  header.small {
    @apply rounded-t-none;

    height: 64px;
    transition: all ease-in-out 0.2s;
    box-shadow: rgba(0, 0, 0, 0.1) 0 3px 5px;
  }

  nav {
    @apply flex flex-row justify-between w-screen;
  }

  .burger {
    @apply flex lg:hidden;
  }
</style>

<header class:small>
  <nav>
    {#if noSidebar === false}
      <div class="burger">
        <Hamburger bind:open={sidebar} {small} />
      </div>
    {/if}

    <Title {small} />
  </nav>
</header>
