<script lang="ts">
  import page from 'page';
  import Decision from 'pages/Decision.svelte';
  import NotFound from 'pages/NotFound.svelte';
  import Search from 'pages/Search.svelte';
  import SearchResults from 'pages/SearchResults.svelte';
  import Version from 'pages/Version.svelte';
  import { appState } from './stores';
  import { getSearchParams } from './utils/params';

  let isInitialized = appState.isInitialized;
  let currentPage: unknown = null;
  let pageProps: Record<string, unknown> = {};

  const clearPageProps = (ctx, next) => {
    pageProps = {};
    next();
  };

  page('/', clearPageProps, getSearchParams, (ctx) => {
    pageProps.pageParams = ctx.pageParams;
    currentPage = Search;
  });

  page('/search', clearPageProps, getSearchParams, (ctx) => {
    pageProps.pageParams = ctx.pageParams;
    currentPage = SearchResults;
  });

  page('/decisions/:decision_id/:file_id?', clearPageProps, (ctx) => {
    pageProps = ctx.params;
    currentPage = Decision;
  });

  page('/version', clearPageProps, () => {
    currentPage = Version;
  })

  page('*', clearPageProps, () => {
    currentPage = NotFound;
  });

  page.start();
</script>

<svelte:component
  {...pageProps}
  ready={$isInitialized}
  this={currentPage}
/>
