import { api, okRequest } from './api';

export type CitationInfo = {
  id: string,
  name: string,
  classifier: string,
};

export type TitleInfo = {
  id: string,
  title: string,
  classifier: string,
};

export type JudgeInfo = {
  id: string,
  name: string,
  classifier: string,
};

export type CounselInfo = {
  id: string,
  name: string,
  classifier: string,
};

export type PartyInfo = {
  id: string,
  name: string,
  classifier: string,
};

export type IssuerInfo = {
  name: string,
};

export type DocumentPreview = {
  id: string,
  document_id: string,
  filetype: string,
  label: string,
  storage_url: string,
  tag: string
};

export type DecisionInfo = {
  id: string,
  date: string,
  docket_number: string,
  country: string,
  language: string,
  jurisdiction: string,
  database: string,
  issuer: IssuerInfo,
  citations: Array<CitationInfo>,
  judges: Array<JudgeInfo>,
  counsels: Array<CounselInfo>,
  titles: Array<TitleInfo>,
  parties: Array<PartyInfo>,
  documents: Array<DocumentPreview>,
  topics: Array<Topic>,
};

export interface Topic {
  topic_name: string
  number: string
  subject_matter_name: string
  headings: Array<Heading>
}

export interface Heading {
  name: string
}

export async function getDecision(id: string): Promise<DecisionInfo> {
  return okRequest(api.get(`/decisions/${id}`)).then((response: {
    id: string, data: string,
    documents_data: string,
    topics_data: string
  }) => {
    const decision = JSON.parse(response.data);
    decision.documents = JSON.parse(response.documents_data);
    decision.topics = JSON.parse(response.topics_data);
    return decision;
  });
}
