<script lang="ts">
  import Base from './Base.svelte';
</script>

<Base tooltipID="judge">
  <div class="text-lg font-bold">
    Judge filter
  </div>
  <div>
    Include only documents that contains the specified judge's name.
  </div>

  <div class="examples">
    <div class="item">
      <div class="title">Partial name</div>
      <div class="field">
        gagnon
      </div>
      <div>
        Include only documents with a judge recorded as "gagnon".
      </div>
    </div>

    <div class="item">
      <div class="title">Exact phrase</div>
      <div class="field">
        "stone and linden"
      </div>
      <div>
        Include only documents with a judge recorded as "stone and linden".
      </div>
    </div>
  </div>
</Base>
