<script lang="ts">
  export let property: string;
  export let collection: Array<unknown>;
</script>

<style lang="postcss">
  ul {
    @apply text-sm;
  }

  ul li {
    @apply text-sm ml-2;
  }

  .number {
    @apply text-xs text-gray-500 font-mono;
  }

  .value {
    @apply text-sm;
  }
</style>

{#if collection !== undefined}
  <ul>
    {#each collection as item, n}
      <li>
        <span class="number">({n + 1}/{collection.length})</span>
        <span class="value">{item[property]}</span>
      </li>
    {/each}
  </ul>
{:else}
  <div>-</div>
{/if}

