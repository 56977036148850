export function selectCitation(citations: Array<{ name: string, classifier: string }>): string {
  let neutral = '';
  let other = '';
  let canlii = '';
  let official = '';
  let citation = '';
  let mlb_unedited = '';
  let mlb_official = '';
  let mlb_parallel = '';
  let quicklaw = '';
  let carswell = '';
  let soquij = '';

  citations.forEach(c => {
    switch (c.classifier.toLowerCase()) {
      case 'neutral':
        neutral = neutral || c.name;
        break;
      case 'other':
        other = other || c.name;
        break;
      case 'canlii':
        canlii = canlii || c.name;
        break;
      case 'official':
        official = official || c.name;
        break;
      case 'mlb unedited':
        mlb_unedited = mlb_unedited || c.name;
        break;
      case 'mlb official':
        mlb_official = mlb_official || c.name;
        break;
      case 'mlb parallel citation':
        mlb_parallel = mlb_parallel || c.name;
        break;
      case 'quicklaw':
        quicklaw = quicklaw || c.name;
        break;
      case 'carswell':
        carswell = carswell || c.name;
        break;
      case 'soquij':
        soquij = soquij || c.name;
        break;
    }
  });

  citation = neutral || other || canlii || official || mlb_official || mlb_unedited || mlb_parallel || quicklaw || carswell || soquij;
  return citation;
}
