<script lang="ts">
  import FieldContainer from './FieldContainer.svelte';
  import { showMe } from 'utils/tippy';

  // The placeholder text
  export let placeholder = '';

  // Show the placeholder text
  export let showPlaceholder = false;

  // Automatically selects the text when focused
  export let autoselect = false;

  export let label = '';
  export let showLabel = true;
  export let value = '';

  // Hover
  export let showme = '';

  // States
  export let disabled = false;
  export let ready = true;

  let ref;

  function onfocus() {
    if (autoselect) {
      ref.select();
    }
  }

  export function focus(): void {
    onfocus();
  }
</script>

<style lang="postcss">
  .field {
    @apply text-lg border-0 p-2 w-full text-sm select-all;

    border-width: 1px;
    border-color: #cdcdcd;
  }

  .autoselect {
    @apply select-all;
  }

  .striped {
    background: repeating-linear-gradient(45deg, transparent, transparent 10px, #eee 10px, #eee 20px);
  }
</style>

<FieldContainer label={label} showLabel={showLabel}>
  <div use:showMe={{ template: showme }}>
    <input autocapitalize="off" autocomplete="off"
           bind:this={ref}
           bind:value={value}
           class="field {ready ? '' : 'striped'} autoselect"
           {disabled}
           minlength="3"
           on:focus={onfocus}
           placeholder={showPlaceholder ? placeholder : ''}
           spellcheck="false"
           tabindex="0"
           type="text"
    />
  </div>
</FieldContainer>
