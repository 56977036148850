<script lang="ts">
  import Tailwind from './Tailwind.svelte';
</script>

<Tailwind />

<!-- Page up to browser edge -->
<div class="lg:p-4">
  <div class="mx-auto max-w-[2000px] shadow-md">
    <!-- Contents go here -->
    <slot>
    </slot>
  </div>
</div>
