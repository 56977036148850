<script lang="ts">

</script>

<style lang="postcss">
  .page-footer {
    @apply p-8 text-center;
  }

  .footer-text {
    @apply text-blueGray-600 text-sm;
  }
</style>

<div class="page-footer">
  <div class="footer-text">
    &copy; 2021 compass.law
  </div>
</div>
