<script type="ts">
  export let label = '';
  export let showLabel = true;
  export let disabled = false;
</script>

<style lang="postcss">
  .field-container {
    @apply p-2;
  }

  .label-text {
    @apply text-sm subpixel-antialiased uppercase mb-1 block text-gray-500;
  }
</style>

<div class="field-container">
  {#if showLabel && (label !== '')}
    <label>
      <input type="hidden" {disabled} />
      <span class="label-text">{label}</span>
      <slot></slot>
    </label>
  {:else}
    <slot></slot>
  {/if}
</div>
