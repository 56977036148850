<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  export let currentPage: number;
  export let itemsPerPage: number;
  export let total: number;
  export let limitReached = false;

  let totalPages: number;

  const dispatch = createEventDispatcher();

  $: {
    totalPages = Math.floor(total / itemsPerPage) + 1;
  }

  function goPreviousPage() {
    dispatch('previousPage');
  }

  function goNextPage() {
    dispatch('nextPage');
  }
</script>

<style lang="postcss">
  .pager-button {
    @apply text-base uppercase underline font-semibold text-gray-500 py-4;
  }

  .right {
    @apply pr-4 sm:pr-0;
  }

  .left {
    @apply pl-4 sm:pr-0;
  }
</style>

<div class="border-t border-gray-200">
  <div class="flex flex-row p-2 text-md">
    <div class="w-full">
      {#if currentPage > 1}
        <button class="pager-button left" on:click={goPreviousPage}>
          Previous: {currentPage - 1}
        </button>
      {/if}
    </div>

    <div class="w-full text-right">
      {#if (currentPage < totalPages) && !limitReached}
        <button class="pager-button right" on:click={goNextPage}>
          Next: {currentPage + 1} of {totalPages}
        </button>
      {/if}
    </div>
  </div>

</div>
