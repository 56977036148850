<script lang="ts">
  import { getIssuers } from 'api';
  import DeviceDetector from 'components/elements/DeviceDetector.svelte';
  import DateField from 'components/fields/DateField.svelte';
  import FieldContainer from 'components/fields/FieldContainer.svelte';
  import TextField from 'components/fields/TextField.svelte';
  import { createEventDispatcher, onMount } from 'svelte';
  import Select from 'svelte-select';
  import { SearchHash } from 'utils/querystring';
  import { settings } from 'utils/settings';
  import CitationFilter from './tooltips/CitationFilter.svelte';
  import CounselFilter from './tooltips/CounselFilter.svelte';
  import FullText from './tooltips/FullText.svelte';
  import JudgeFilter from './tooltips/JudgeFilter.svelte';
  import TitleFilter from './tooltips/TitleFilter.svelte';
  import TopicFilter from './tooltips/TopicFilter.svelte';

  // Look and feel params
  export let showLabels = true;
  export let showPlaceholders = false;
  export let wideMode = true;
  export let showAdvanced = false;
  export let hideAdvancedToggle = false;

  // Controls
  export let heading = 'Compass Search';
  export let searchButtonTitle = 'Search';

  // Search fields
  export let fullTextSearchTerm = '';
  export let dateFrom = '';
  export let dateTo = '';
  export let citation = '';
  export let docketNumber = '';
  export let jurisdiction = '';
  export let database = '';
  export let title = '';
  export let issuer = '';
  export let judge = '';
  export let party = '';
  export let counsel = '';
  export let topic = '';

  // Internal
  let jurisdictionList: { label: string, value: string }[] = [];
  $: {
    if (jurisdiction === '' || jurisdiction === undefined) {
      jurisdictionList = [];
    } else {
      jurisdictionList = jurisdiction.split(',').map(j => {
        return { label: j, value: j };
      });
    }
  }

  let issuerList: { label: string, value: string }[] = [];
  $: {
    if (issuer === '' || issuer === undefined) {
      issuerList = [];
    } else {
      issuerList = issuer.split(',').map(v => {
        return { label: v, value: v };
      });
    }
  }

  let databaseList: { label: string, value: string }[] = [];
  $: {
    if (database === '' || database === undefined) {
      databaseList = [];
    } else {
      databaseList = database.split(',').map(v => {
        return { label: v, value: v };
      });
    }
  }

  // State
  export let ready = true;
  export let disabled = false;

  // Others
  const dispatch = createEventDispatcher();

  // Events:
  // 'submitSearch' -> <SearchHash>
  // 'change' -> Record<string, unknown>

  // Data
  const jurisdictionOptions = [
    'Alberta',
    'British Columbia',
    'Federal Jurisdiction (Canada)',
    'Manitoba',
    'Ontario',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Prince Edward Island',
    'Saskatchewan',
    'Yukon',
  ];

  let issuerOptions = [];
  let fulltextfield = null;

  onMount(async () => {
    const issuers = await getIssuers();
    issuerOptions = issuers.map(i => i.name);
  });

  // Set search button title default
  $: {
    if (searchButtonTitle === '') {
      searchButtonTitle = 'Search';
    }
  }

  // Propagate changes
  let returnJurisdictionStr: string;
  $: {
    if (jurisdictionList) {
      returnJurisdictionStr = jurisdictionList.map(j => j.value).join(',');
    } else {
      returnJurisdictionStr = '';
    }
  }

  let returnIssuerStr: string;
  $: {
    if (issuerList) {
      returnIssuerStr = issuerList.map(v => v.value).join(',');
    } else {
      returnIssuerStr = '';
    }
  }

  let returnDatabaseStr: string;
  $: {
    if (databaseList) {
      returnDatabaseStr = databaseList.map(v => v.value).join(',');
    } else {
      returnDatabaseStr = '';
    }
  }

  $: {
    const props: Record<string, string> = {
      fulltext_search_term: fullTextSearchTerm,
      date_from: dateFrom,
      date_to: dateTo,
      citation: citation,
      docket_number: docketNumber,
      jurisdiction: returnJurisdictionStr,
      issuer: returnIssuerStr,
      database: returnDatabaseStr,
      judge: judge,
      party: party,
      counsel: counsel,
      topic: topic,
      title: title,
    };
    dispatch('change', props);
  }

  function onFormSubmit(): void {
    if (disabled || !ready) {
      return;
    }

    let jurisdictionStr = '';
    if (jurisdictionList) {
      jurisdictionStr = jurisdictionList.map(j => j.value).join(',');
    }

    let issuerStr = '';
    if (issuerList) {
      issuerStr = issuerList.map(v => v.value).join(',');
    }

    let databaseStr = '';
    if (databaseList) {
      databaseStr = databaseList.map(v => v.value).join(',');
    }

    const searchHash: SearchHash = <SearchHash>{
      fullTextSearchTerm,
      dateFrom,
      dateTo,
      citation,
      docketNumber,
      jurisdiction: jurisdictionStr,
      issuer: issuerStr,
      database: databaseStr,
      judge,
      counsel,
      party,
      topic,
      title,
    };

    dispatch('submitSearch', searchHash);
  }

  function toggleAdvanced(): void {
    $settings['show_advanced_filters'] = !$settings['show_advanced_filters'];
  }
</script>

<style lang="postcss">
  .search-button {
    @apply bg-black text-white p-2 m-2;
  }

  .search-button:disabled {
    @apply bg-gray-400;
  }

  .wide .field-row {
    @apply flex md:flex-row flex-col;
  }

  .field-row {
    @apply flex flex-col;
  }

  .wide .field-item {
    @apply md:w-1/2 w-full;
  }

  .field-item {
    @apply w-full text-xs;
  }

  .touch-action {
    touch-action: manipulation;
  }
</style>

<FullText />
<TitleFilter />
<CitationFilter />
<JudgeFilter />
<TopicFilter />
<CounselFilter />

<div class="bg-white p-4">
  <div class="font-bold text-2xl pl-2">{heading}</div>

  <form on:submit|preventDefault={onFormSubmit}>
    <div class="flex flex-col {wideMode ? 'wide': ''}">

      <!-- Full text field -->
      <div class="flex-auto w-full">
        <TextField autoselect={true}
                   bind:this={fulltextfield}
                   bind:value={fullTextSearchTerm}
                   {disabled}
                   label="Full-text search"
                   placeholder="Enter search terms"
                   {ready}
                   showLabel={showLabels}
                   showPlaceholder={showPlaceholders}
                   showme="fulltext"
        />
      </div>

      <!-- Controls -->
      {#if !hideAdvancedToggle}
        <div class="w-full p-2">
          <button class="text-sm uppercase text-gray-500 font-bold touch-action" on:click={toggleAdvanced} tabindex="0"
                  type="button">
            {#if showAdvanced}
              Hide advanced filters
            {:else}
              Show advanced filters
            {/if}
          </button>
        </div>
      {/if}

      <!-- Filters -->
      <div class="{showAdvanced ? 'block' : 'hidden'}">
        <!-- Dates (desktop view) -->
        <DeviceDetector showInDevice="desktop">
          <div class="w-full flex flex-col md:flex-row">
            <div class="w-full md:w-1/2">
              <DateField bind:value={dateFrom}
                         {disabled}
                         label="Date from" />
            </div>
            <div class="w-full md:w-1/2">
              <DateField bind:value={dateTo}
                         {disabled}
                         label="Date to" />
            </div>
          </div>
        </DeviceDetector>

        <!-- Dates (mobile view) -->
        <DeviceDetector showInDevice="mobile">
          <div class="w-full flex flex-col">
            <div class="w-full">
              <DateField bind:value={dateFrom}
                         {disabled}
                         label="Date from" />
            </div>
            <div class="w-full">
              <DateField bind:value={dateTo}
                         {disabled}
                         label="Date to" />
            </div>
          </div>
        </DeviceDetector>

        <div class="field-row">
          <!-- Title -->
          <div class="field-item">
            <TextField autoselect={true}
                       bind:value={title}
                       {disabled}
                       label="Title"
                       placeholder="Title"
                       showLabel={showLabels}
                       showPlaceholder={showPlaceholders}
                       showme="title"
            />
          </div>

          <!-- Citation -->
          <div class="field-item">
            <TextField autoselect={true}
                       bind:value={citation}
                       {disabled}
                       label="Citation"
                       placeholder="Citation"
                       showLabel={showLabels}
                       showPlaceholder={showPlaceholders}
                       showme="citation"
            />
          </div>
        </div>

        <div class="field-row">
          <!-- Docket number -->
          <div class="field-item">
            <TextField autoselect={true}
                       bind:value={docketNumber}
                       {disabled}
                       label="Docket number"
                       placeholder="Docket number"
                       showLabel={showLabels}
                       showPlaceholder={showPlaceholders}
            />
          </div>

          <!-- Jurisdiction selector -->
          <div class="field-item text-xs">
            <FieldContainer label="Jurisdiction">
              <Select bind:value={jurisdictionList}
                      inputAttributes={{ tabindex: "0"}}
                      inputStyles="height: 36px;"
                      isDisabled={disabled}
                      isMulti={true}
                      items={jurisdictionOptions}
              />
            </FieldContainer>
          </div>
        </div>

        <div class="field-row">
          <!-- Issuer selector -->
          <div class="field-item">
            <FieldContainer label="Issuer">
              <Select bind:value={issuerList}
                      inputAttributes={{ tabindex: "0" }}
                      inputStyles="height: 36px;"
                      isDisabled={disabled}
                      isMulti={true}
                      items={issuerOptions}
              />
            </FieldContainer>
          </div>

          <!-- Judge -->
          <div class="field-item">
            <TextField autoselect={true}
                       bind:value={judge}
                       {disabled}
                       label="Judge"
                       placeholder="Judge"
                       showLabel={showLabels}
                       showPlaceholder={showPlaceholders}
                       showme="judge"
            />
          </div>
        </div>

        <div class="field-row">
          <!-- Topic -->
          <div class="field-item">
            <TextField autoselect={true}
                       bind:value={topic}
                       {disabled}
                       label="Topic"
                       placeholder="Topic"
                       showLabel={showLabels}
                       showPlaceholder={showPlaceholders}
                       showme="topic"
            />
          </div>

          <!-- Counsel -->
          <div class="field-item">
            <TextField autoselect={true}
                       bind:value={counsel}
                       {disabled}
                       label="Counsel"
                       placeholder="Counsel"
                       showLabel={showLabels}
                       showPlaceholder={showPlaceholders}
                       showme="counsel"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row items-center">
      <div class="w-1/2">
        <button class="search-button" disabled={disabled || !ready} tabindex="0" type="submit">
          {searchButtonTitle}
        </button>
      </div>

      <div class="w-1/2 text-right pr-2">
        <div class="text-xs uppercase text-gray-600">
          <label>
            <input bind:checked={$settings['show_tooltips']} class="bg-gray-300" tabindex="0" type="checkbox" />
            <span class="hover:underline cursor-pointer">Show tooltips</span>
          </label>
        </div>
      </div>
    </div>
  </form>
</div>
