import { api, okRequest } from './api';

export type HelloResult = {
  version: string
  t1: string
  ws: string
  say: string
  user: {
    id: string,
    roles: string[]
  }
};

export async function hello(): Promise<HelloResult> {
  return okRequest(api.get('/hello'));
}
