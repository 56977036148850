import { Readable, readable } from 'svelte/store';

// bodySizer returns a readable store that reads the width of the page's body element.
export function bodySizer(): Readable<unknown> {
  const body = document.getElementsByTagName('body')[0];

  return readable(body, function start(set: (unknown) => void) {
    const observer = new ResizeObserver(entries => {
      const width = entries[0].contentRect.width;
      set(width);
    });

    observer.observe(body);

    return function stop() {
      observer.disconnect();
    }
  });
}
