<script lang="ts">
  import { DecisionInfo, getDecision } from 'api';
  import DecisionContents from 'components/decision/DecisionContents.svelte';
  import DecisionMetadata from 'components/decision/DecisionMetadata.svelte';
  import BaseLayout from 'components/elements/BaseLayout.svelte';
  import DeviceDetector from 'components/elements/DeviceDetector.svelte';
  import AdBar from 'components/searchresults/AdBar.svelte';
  import Navbar from 'components/sidebar/Navbar.svelte';
  import Icon from 'images/icon1x.png';
  import { onMount } from 'svelte';
  import { DialogContent, DialogOverlay } from 'svelte-accessible-dialog';
  import { MetaTags } from 'svelte-meta-tags';
  import { basePageMeta } from 'utils/page';
  import { bodySizer } from 'utils/sizer';

  export let ready = false;
  export let decision_id: string;
  export let file_id = '';

  let decision: DecisionInfo;
  let openSidebar = false;
  let loading = false;
  let error = null;

  // Automatically close the sidebar when the page gets wide enough.
  const bodysizer = bodySizer();
  $: {
    if (openSidebar && $bodysizer > 1000) {
      openSidebar = false;
    }
  }

  onMount(async () => {
    // Set loading
    loading = true;

    try {
      decision = await getDecision(decision_id);
    } catch (err) {
      error = err;
    } finally {
      loading = false;
    }
  });

  function showMetadata() {
    openSidebar = !openSidebar;
  }
</script>

<style lang="postcss">
  .touch-action {
    touch-action: manipulation;
  }

  :global([data-svelte-dialog-content].content) {
    @apply w-screen sm:w-[90vw] mx-auto mt-[96px] max-w-[580px] rounded-xl p-4 pt-0;
  }

  :global([data-svelte-dialog-overlay].overlay) {
    @apply w-screen h-screen;

    background: rgba(0, 0, 0, 0.66);
  }

  .close-button {
    @apply text-right text-4xl font-bold outline-none;
  }
</style>

{#if decision}
  <MetaTags
    title="Compass.law search - {(decision.titles && decision.titles[0]) ? decision.titles[0].title : ''}"
    description=""
    robotsProps={{
      notranslate: true,
    }}
    additionalMetaTags={[
      ...basePageMeta
    ]}
    openGraph={{
      url: 'https://search.compass.law/decisions/' + decision.id,
      title: `Compass law search - ${decision.titles && decision.titles[0] ? decision.titles[0].title : ''}`,
      description: decision.titles && decision.titles[0] ? decision.titles[0].title : '',
      site_name: 'compass.law search',
      additionalLinkTags: [
        {
          rel: 'apple-touch-icon',
          href: Icon,
          sizes: '301x301',
        },
      ],
      images: [
        {
          url: Icon,
          alt: 'Compass Law Search',
          height: 301,
          width: 301,
        },
      ]
    }}
  />
{/if}

<BaseLayout>
  {#if error && error.status === 404}
    <Navbar noSidebar={true} />
    <div class="text-2xl text-center p-[96px] uppercase font-mono">
      Document not found
    </div>
  {/if}

  {#if decision}
    <!-- Nav bar -->
    <Navbar bind:sidebar={openSidebar} />

    <!-- Show metadata button -->
    <div class="w-full bg-gray-600 text-white px-4 py-2 lg:hidden">
      <button on:click={showMetadata} class="uppercase text-sm underline touch-action">
        {#if openSidebar}
          Hide metadata
        {:else}
          Show metadata
        {/if}
      </button>
    </div>

    <!-- Metadata in the modal -->
    <DialogOverlay class="overlay" isOpen={openSidebar} onDismiss={() => { openSidebar = false; }}>
      <DialogContent class="content">
        <div class="w-full text-right pb-2">
          <button class="close-button" on:click={() => { openSidebar = false; }}>&times;</button>
        </div>
        <DecisionMetadata {decision} />
      </DialogContent>
    </DialogOverlay>

    <div class="bg-white w-full flex flex-row {ready}">
      <!-- Metadata in the content -->
      <div class="w-1/4 p-4 min-w-[325px] hidden lg:block">
        <DecisionMetadata {decision} />
      </div>

      <!-- Contents area -->
      <div class="flex-item w-full p-4 pt-10">
        <DecisionContents {decision} file_id={file_id} />
      </div>

      <!-- Ads -->
      <DeviceDetector showInDevice="desktop">
        <div class="flex-item w-1/8 p-4 hidden xl:block">
          <AdBar />
        </div>
      </DeviceDetector>
    </div>
  {/if}
</BaseLayout>
