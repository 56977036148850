<script lang="ts">
  import Base from './Base.svelte';
</script>

<Base tooltipID="counsel">
  <div class="text-lg font-bold">
    Counsel filter
  </div>

  <div>
    Include only documents that contains the specified counsel's name.
  </div>

  <div class="examples">
    <div class="item">
      <div class="title">Partial name</div>
      <div class="field">
        kathryn
      </div>
      <div>
        Include only documents with a counsel recorded as "kathryn".
      </div>
    </div>

    <div class="item">
      <div class="title">Exact phrase</div>
      <div class="field">
        "Parlee MacLaws LLP"
      </div>
      <div>
        Include only documents with a counsel recorded as "Parlee McLaws LLP".
      </div>
    </div>
  </div>
</Base>
