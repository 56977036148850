<script lang="ts">
  import { settings } from 'utils/settings';

  export let tooltipID = '';

  function disableTooltips() {
    $settings['show_tooltips'] = false;
  }
</script>

<style lang="postcss">
  .pop::before {
    content: "";
    width: 0px;
    height: 0px;
    border: 0.8em solid transparent;
    position: absolute;
  }

  .pop.bottom::before {
    left: 25%;
    top: -21px;
    border-bottom: 10px solid rgba(30, 41, 59, 0.85);
  }

  .footer {
    @apply pt-6 text-right;
  }

  .base {
    @apply bg-blueGray-700 text-gray-100 p-4 text-sm w-[380px] md:w-[640px] shadow-lg rounded-md;
  }

  :global(.examples) {
    @apply p-2 flex flex-col;
  }

  :global(.examples input) {
    @apply text-black p-1 px-2 w-full font-mono select-all mb-1;
  }

  :global(.examples .item) {
    @apply block py-2;
  }

  :global(.examples .item .title) {
    @apply font-bold mb-1;
  }

  :global(.examples .item .field) {
    @apply font-mono p-2 bg-white border border-gray-600 text-black text-sm select-all cursor-text rounded;
  }
</style>

<div class="hidden">
  <div class="base pop bottom" id={tooltipID}>
    <div class="flex flex-col">
      <div class="w-full">
        <slot></slot>
      </div>

      <div class="footer">
        <div class="text-blueGray-400">
          <span class="underline hover:text-white text-xs uppercase cursor-pointer" on:click|preventDefault|stopPropagation={disableTooltips}
                tabindex="0">
            Hide tooltips
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
