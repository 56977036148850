<script lang="ts">
  import { Topic } from 'api';

  export let topics: Array<Topic> = [];

  $: numTopics = topics ? topics.length : 0;
</script>

<style lang="postcss">
  .topics {
    @apply space-y-2;
  }

  .topic {
    @apply text-gray-600 ml-2;
  }

  .counter {
    @apply text-xs font-mono;
  }

  .subject-matter {
    @apply text-gray-800 text-sm font-semibold;
  }

  .headings {
    @apply text-xs uppercase max-w-sm;
  }
</style>

<div class="topics">
  {#if numTopics === 0}
    -
  {/if}

  {#each topics as topic, tn}
    <div class="topic">
      <div>
        <span class="counter">({tn + 1}/{numTopics})</span>
        <span class="subject-matter">{topic.subject_matter_name} - {topic.number}</span>
      </div>
      <div class="headings">
        {#each topic.headings as heading}
          <span>{heading.name}&nbsp;/&nbsp;</span>
        {/each}
        <span>{topic.topic_name}</span>
      </div>
    </div>
  {/each}
</div>
