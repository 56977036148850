<script lang="ts">
  import { createEventDispatcher } from 'svelte';

  // Values
  export let value = '';

  const dispatch = createEventDispatcher();

  function onChange(e) {
    dispatch('change', e.target.value);
  }
</script>

<style lang="postcss">
  .selector {
    @apply border border-gray-400 p-2 rounded-md text-xs uppercase;

    -webkit-appearance: none;
    appearance: none;
    background: transparent;
  }
</style>

<div class="w-full text-xs sm:text-base">
  <select class="selector" on:change={onChange} {value}>
    <option value="">Most relevant first</option>
    <option value="newest">Newest decisions first</option>
    <option value="oldest">Oldest decisions first</option>
  </select>
</div>
