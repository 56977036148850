import { api, okRequest } from './api';

export type SearchResult = {
  hits: number,
  items: Array<SearchResultItem>,
  limit: number,
  limit_reached: boolean,
};

export type SearchResultItem = {
  decisionID: string,
  date: string,
  docket_number: string,
  jurisdiction: string,
  country: string,
  language: string,
  hearingDate: string,
  database: string,
  registry: string,
  issuer: string,
  titles: Array<TitleResultItem>,
  citations: Array<CitationResultItem>,
  judges: Array<string>,
  counsels: Array<string>,
  parties: Array<string>,
};

export interface TitleResultItem {
  title: string;
  classifier: string;
}

export interface CitationResultItem {
  name: string;
  classifier: string;
}

export type SearchRequest = {
  fulltext_search_term: string,
  date_from: string,
  date_to: string,
  citation: string,
  docket_number: string,
  jurisdiction: Array<string>,
  database: Array<string>,
  issuer: Array<string>,
  judge: string,
  party: string,
  topic: string,
  counsel: string,
  title: string,
  per_page: number,
  page: number,
  order: string,
};

export function isEmptySearchRequest(req: SearchRequest): boolean {
  return !req.fulltext_search_term && !req.date_from && !req.date_to && !req.citation &&
    !req.docket_number && !req.jurisdiction && !req.database && !req.judge && !req.party && !req.counsel &&
    !req.issuer && !req.topic && !req.title;
}

export async function search(req: SearchRequest): Promise<SearchResult> {
  req.page ||= 1;

  return okRequest(api.get('/decisions', {
    params: req,
  }));
}
