<script lang="ts">
  export let open = false;
  export let small = false;

  let height: number;
  let width: number;
  $: {
    width = small ? 26 : 32;
    height = small ? 16 : 24;
  }

  function clickHandler() {
    open = !open;
  }
</script>

<style lang="postcss">
  svg {
    min-height: 16px;
    transition: transform 0.3s ease-in-out;
  }

  svg line {
    stroke: currentColor;
    stroke-width: 3;
    transition: transform 0.3s ease-in-out;
  }

  button {
    z-index: 20;
  }

  .open svg {
    transform: scale(0.7);
    transition: transform 0.3s ease-in-out;
  }

  .open #top {
    transform: translate(6px, 0) rotate(45deg);
    transition: transform 0.3s ease-in-out;
  }

  .open #middle {
    opacity: 0;
  }

  .open #bottom {
    transform: translate(-12px, 9px) rotate(-45deg);
    transition: transform 0.3s ease-in-out;
  }

  .touch-action {
    touch-action: manipulation;
  }

  .burger {
    @apply text-gray-100 hover:text-white cursor-pointer mr-4 border-none focus:outline-none;
    @apply ml-4;
  }
</style>

<button class="burger touch-action" class:open on:click={clickHandler}>
  <svg height={height} width={width}>
    <line id="top" x1="0" x2={width} y1="2" y2="2" />
    <line id="middle" x1="0" x2={width} y1={height/2} y2={height/2} />
    <line id="bottom" x1="0" x2={width} y1={height-2} y2={height-2} />
  </svg>
</button>
