import { hello } from 'api';
import { backOff } from 'exponential-backoff';
import { writable, Writable } from 'svelte/store';

interface User {
  id: string,
  roles: string[],
}

class AppState {
  // isLoading is true while the app is loading.
  public readonly isLoading: Writable<boolean>;

  // isInitialized is true after the app has successfully loaded.
  public readonly isInitialized: Writable<boolean>;
  // version is the API server version.
  public readonly version: Writable<string>;
  // currentUser is the current logged-in user.
  public readonly currentUser: Writable<User | null>;
  // error is the error that occurred when loading the app.
  public readonly error: Writable<unknown>;

  constructor() {
    this.isLoading = writable(false);
    this.isInitialized = writable(false);
    this.version = writable('');
    this.currentUser = writable(null);
    this.error = writable(null);
  }
}

export const appState = new AppState();

// loadApp loads the initial app data. It sends a hello message
// to the API server and saves the info about the server version
// and the currently logged-in user.
export async function loadApp(): Promise<void> {
  appState.isLoading.set(true);

  try {
    const res = await backOff(() => hello());
    appState.version.set(res.version);

    if (res.user) {
      appState.currentUser.set({
        id: res.user.id,
        roles: res.user.roles,
      });
    }

    appState.error.set(null);
    appState.isInitialized.set(true);
  } catch (err) {
    appState.error.set(err);
    appState.isInitialized.set(false);
  } finally {
    appState.isLoading.set(false);
  }
}
