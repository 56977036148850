<script lang="ts">
  export let count: number;
  export let currentPage: number;
  export let perPage: number;
  export let total: number;
  export let limit: number;

  let f1: number;
  let f2: number;

  $: {
    f1 = perPage * (currentPage - 1) + 1;
    f2 = f1 + count - 1;
  }

  let displayedTotal: number;
  $: {
    if (limit > 0 && total > limit) {
      displayedTotal = limit;
    } else {
      displayedTotal = total;
    }
  }
</script>

<div class="">
  <div class="flex flex-col xs:flex-row">
    <div class="min-w-[100px]">
      {total} results.
    </div>

    <div class="">
      Showing {f1} to {f2} of {displayedTotal}
    </div>
  </div>
</div>

<div class="text-xs pt-2 hidden">
  count = {count}, currentPage = {currentPage}, perPage = {perPage}, total = {total}
</div>
