<script lang="ts">
  import SearchFilters from './SearchFilters.svelte';

  // Look and feel
  export let showLabels = true;
  export let showPlaceholders = false;
  export let wideMode = true;
  export let showAdvanced = false;

  // Controls
  export let heading = 'Compass Search';
  export let searchButtonTitle = 'Search';
  export let hideAdvancedToggle = false;

  // Component state
  export let ready = true;
  export let disabled = false;

  // Data
  export let filterParams: Record<string, string> = {};
</script>

<SearchFilters bind:citation={filterParams['citation']}
               bind:counsel={filterParams['counsel']}
               bind:database={filterParams['database']}
               bind:dateFrom={filterParams['date_from']}
               bind:dateTo={filterParams['date_to']}
               bind:docketNumber={filterParams['docket_number']}
               bind:fullTextSearchTerm={filterParams['fulltext_search_term']}
               bind:issuer={filterParams['issuer']}
               bind:judge={filterParams['judge']}
               bind:jurisdiction={filterParams['jurisdiction']}
               bind:party={filterParams['party']}
               bind:title={filterParams['title']}
               bind:topic={filterParams['topic']}
               {disabled}
               {heading}
               {hideAdvancedToggle}
               on:change
               on:submitSearch
               {ready}
               {searchButtonTitle}
               {showAdvanced}
               {showLabels}
               {showPlaceholders}
               {wideMode}
/>
